import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const CareerForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone_number: "",
    address: "",
    
    jobTitle: "",
    department: "",
    
    message: "",
    resumeBase64: "", // Storing Base64 format
   
   
  });

  const [captchaValue, setCaptchaValue] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = async (e) => {
    const { name, value, type, checked, files } = e.target;
    if (type === "file") {
      const file = files[0];
      if (file) {
        try {
          const base64 = await convertFileToBase64(file);
          setFormData((prevData) => ({
            ...prevData,
            resumeBase64: base64,
          }));
        } catch (error) {
          console.error("Error converting file to Base64:", error);
        }
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result.split(",")[1]); // Extract Base64 portion
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!captchaValue) {
      alert("Please complete the reCAPTCHA.");
      return;
    }

    setLoading(true);

    const payload = {
      ...formData,
      resume: formData.resumeBase64, // Base64 encoded resume
    };

    try {
      const response = await fetch("https://www.chemilex.com/career.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (response.ok) {
        alert("Application submitted successfully!");
        setFormData({
          name: "",
          email: "",
          phone_number: "",
          address: "",
           
          jobTitle: "",
          department: "",
           
          message: "",
          resumeBase64: "",
          
        });
      } else {
        alert(`Failed to submit application: ${data.message}`);
      }
    } catch (error) {
      console.error("Error submitting application:", error);
      alert("An error occurred while submitting your application.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="section-full">
      <div className="container">
        <div className="section-head">
          <h2 className="AgencyFontheading">Career Application Form</h2>
          <div className="mt-separator-outer m-b30">
            <div className="mt-separator site-bg-primary" />
          </div>
        </div>
        <div className="section-content m-b50">
          <div className="mt-box">
            <div className="p-a30 bg-gray radius-10 cons-contact-form-wrap">
              <form
                className="cons-contact-form contact-style-1"
                onSubmit={handleSubmit}
                encType="multipart/form-data"
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        name="fullName"
                        type="text"
                        required
                        className="form-control"
                        placeholder="Full Name"
                        value={formData.fullName}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        name="email"
                        type="email"
                        required
                        className="form-control"
                        placeholder="Email Address"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        name="phone"
                        type="tel"
                        required
                        className="form-control"
                        placeholder="Phone Number"
                        value={formData.phone}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        name="address"
                        type="text"
                        required
                        className="form-control"
                        placeholder="Address"
                        value={formData.address}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        name="jobTitle"
                        type="text"
                        required
                        className="form-control"
                        placeholder="Position Applied For"
                        value={formData.jobTitle}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <select
                        name="department"
                        className="form-control"
                        value={formData.department}
                        onChange={handleChange}
                      >
                        <option value="" selected disabled>
                          Desired Role
                        </option>
                        <option value="Entry Level">Entry Level</option>
                        <option value="Intermediate Level">
                          Intermediate Level
                        </option>
                        <option value="Advanced Level">Advanced Level</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <textarea
                        name="coverLetter"
                        rows={5}
                        className="form-control"
                        required
                        placeholder="Cover Letter"
                        value={formData.coverLetter}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 p-b30">
                    <label>Resume/CV:</label>
                    <input
                      type="file"
                      name="resume"
                      className="form-control"
                      onChange={handleChange}
                      required
                      accept=".pdf,.doc,.docx"
                    />
                  </div>
                  <div className="form-group">
                    <ReCAPTCHA
                      sitekey="6LcSWKYqAAAAAGYvChgsi6Cob3xzHlBnEz_3ZoPx"
                      onChange={handleCaptchaChange}
                    />
                  </div>
                  <div className="col-md-12">
                    <button type="reset" className="site-button m-r10">
                      Reset
                    </button>
                    <button
                      type="submit"
                      className="site-button-secondry"
                      disabled={loading}
                    >
                      {loading ? "Submitting..." : "Submit"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerForm;
