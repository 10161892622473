import { useEffect } from "react";
// import FastImage from "../../../globals/elements/fastimg";
// import { loadScript, route } from "../../../globals/constants";
import { NavLink } from "react-router-dom";
import { loadScript, route } from "../../../../globals/constants";
import FastImage from "../../../../globals/elements/fastimg";
import OwlCarousel from "react-owl-carousel";
import ContactForm from "../../contactForm";
function ChinaMetCoke() {
  useEffect(() => {
    loadScript("js/custom.js");
  });

  return (
    <>
      {/* Service Detail */}
      <div className="section-full p-t80 p-b50 ">
        <div className="container bg-white ">
          <div className="section-content ">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="mt-box">
                  <h2 className="m-t0 normalheading" style={{
                  lineHeight:"52px"
                }}>China Met Coke</h2>

                  <p>
                    China Met Coke, or Chinese metallurgical coke, is a
                    premium-grade coke with high carbon content and minimal
                    impurities, essential for steelmaking. Sourced from top
                    coking coal reserves in China, it is characterized by its
                    strength and efficiency in blast furnace operations.
                  </p>
                  <p>
                    The China Met Coke is carefully processed to ensure optimal
                    performance in iron production. Its superior quality
                    enhances productivity and efficiency, making it a trusted
                    choice for steel manufacturers seeking reliable and
                    high-performance coke solutions.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <OwlCarousel
                  className="owl-carousel service-detail-carousel owl-btn-vertical-center owl-dots-bottom-center m-b20"
                  items={1}
                  loop
                  nav
                  dots
                  mouseDrag={false} // Disable mouse dragging
                  touchDrag={false} // Disable touch dragging
                >
                  <div className="item">
                    <div className="aon-thum-bx">
                      <FastImage
                        src="images/products/ChinaMETCoke.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-full p-t80 p-b50 bg-gray">
        <div className="container">
          {/* TITLE START*/}
          <div className="section-head text-center">
            <h2 data-title="Supplies" className="normalheading"> Services</h2>
            <div className="mt-separator-outer">
              <div className="mt-separator site-bg-primary" />
            </div>
          </div>
          {/* TITLE END*/}
          <div className="row d-flex justify-content-center">
            <div className="col-lg-4 col-md-6">
             <div className="mt-box bg-white m-b30 mt-service-2">
                <div className="mt-media">
                  <NavLink to={route.Products.IndonesianMetCoke}>
                    <OwlCarousel
                      className="owl-carousel service-detail-carousel owl-btn-vertical-center owl-dots-bottom-center m-b20"
                      items={1}
                      loop
                      nav
                      dots
                      mouseDrag={false} // Disable mouse dragging
                      touchDrag={false} // Disable touch dragging
                    >
                      <div className="item">
                        <div className="aon-thum-bx">
                          <FastImage
                            src="images/products/IndonesianMETCoke.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                    </OwlCarousel>
                  </NavLink>
                </div>
                <div className="mt-icon-box-wraper p-a30 left bg-white">
                  <div className="icon-content">
                    <h4 className="mt-tilte m-t0">Indonesian Met Coke</h4>

                    <NavLink
                      to={route.Products.IndonesianMetCoke}
                      className="site-button-link site-text-primary font-weight-600"
                    >
                      Read More
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
             <div className="mt-box bg-white m-b30 mt-service-2">
                <div className="mt-media">
                  <NavLink to={route.Products.ColombianMetCoke}>

                  <OwlCarousel
                      className="owl-carousel service-detail-carousel owl-btn-vertical-center owl-dots-bottom-center m-b20"
                      items={1}
                      loop
                      nav
                      dots
                      mouseDrag={false} // Disable mouse dragging
                      touchDrag={false} // Disable touch dragging
                    >
                  
                      <div className="item">
                        <div className="aon-thum-bx">
                          <FastImage
                            src="images/products/Colombian MET COKE.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                      </OwlCarousel>
                  </NavLink>
                </div>
                <div className="mt-icon-box-wraper p-a30 left bg-white">
                  <div className="icon-content">
                    <h4 className="mt-tilte m-t0">Colombian MET Coke</h4>

                    <NavLink
                      to={route.Products.ColombianMetCoke}
                      className="site-button-link site-text-primary font-weight-600"
                    >
                      Read More
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
             <div className="mt-box bg-white m-b30 mt-service-2">
                <div className="mt-media">
                  <NavLink to={route.Products.ColombianULP}>

                  <OwlCarousel
                      className="owl-carousel service-detail-carousel owl-btn-vertical-center owl-dots-bottom-center m-b20"
                      items={1}
                      loop
                      nav
                      dots
                      mouseDrag={false} // Disable mouse dragging
                      touchDrag={false} // Disable touch dragging
                    >
 
                      <div className="item">
                        <div className="aon-thum-bx">
                          <FastImage
                            src="images/products/Colombian (ULP).png"
                            alt=""
                          />
                        </div>
                      </div>
                
                    </OwlCarousel>
                  </NavLink>
                </div>
                <div className="mt-icon-box-wraper p-a30 left bg-white">
                  <div className="icon-content">
                    <h4 className="mt-tilte m-t0">Colombian (ULP)</h4>

                    <NavLink
                      to={route.Products.ColombianULP}
                      className="site-button-link site-text-primary font-weight-600"
                    >
                      Read More
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
             <div className="mt-box bg-white m-b30 mt-service-2">
                <div className="mt-media">
                  <NavLink to={route.Products.IndianLAMCoke}>

                  <OwlCarousel
                      className="owl-carousel service-detail-carousel owl-btn-vertical-center owl-dots-bottom-center m-b20"
                      items={1}
                      loop
                      nav
                      dots
                      mouseDrag={false} // Disable mouse dragging
                      touchDrag={false} // Disable touch dragging
                    >
                    
                      <div className="item">
                        <div className="aon-thum-bx">
                          <FastImage
                            src="images/products/IndianLAMCoke.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                      </OwlCarousel>
                  </NavLink>
                </div>
                <div className="mt-icon-box-wraper p-a30 left bg-white">
                  <div className="icon-content">
                    <h4 className="mt-tilte m-t0"> Indian LAM Coke</h4>

                    <NavLink
                      to={route.Products.IndianLAMCoke}
                      className="site-button-link site-text-primary font-weight-600"
                    >
                      Read More
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactForm/>
    </>
  );
}
export default ChinaMetCoke;
