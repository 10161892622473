import { useEffect, useState } from "react";
import Loader from "./globals/elements/loader";
import ScrollToTop from "./globals/scroll-to-top";
import RootLayout from "./layouts/root-layout";

function App() {

  const [isLoading, setLoading] = useState(true);

  setTimeout(() => {
    setLoading(false);
  }, 2000);

  useEffect(() => {
    // Disable right-click
    const handleRightClick = (e) => {
      e.preventDefault();
    };

    document.addEventListener("contextmenu", handleRightClick);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("contextmenu", handleRightClick);
    };
  }, []);

  return (
    <>
      {isLoading && <Loader />}
     
      <RootLayout />
    </>
  );
}

export default App;
