import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

function ContactForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone_number: "",
    message: "",
  });
  const [captchaValue, setCaptchaValue] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!captchaValue) {
      alert("Please complete the CAPTCHA");
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await fetch("https://www.chemilex.com/contactus.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Failed to submit the form. Please try again.");
      }

      const result = await response.json();
      alert("Form submitted successfully!");
      console.log(result);

      // Reset form data and CAPTCHA
      setFormData({
        name: "",
        email: "",
        number: "",
        message: "",
      });
      setCaptchaValue(null);
    } catch (error) {
      alert(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="section-full p-t80 p-b50 bg-white">
      <div className="container bg-white">
        <div className="section-content">
          <div className="row text-center">
            <div className="col-lg-12 col-md-12">
              {/* Quick Contact */}
              <div className="widget">
                <h2 className="normalheading">Contact Us</h2>
                <div className="widget-quick-form">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <input
                        name="name"
                        placeholder="Name"
                        className="form-control"
                        type="text"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <input
                        name="email"
                        placeholder="Email"
                        className="form-control"
                        type="text"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <input
                        name="phone_number"
                        placeholder="Number"
                        className="form-control"
                        type="number"
                        value={formData.phone_number}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <textarea
                        name="message"
                        placeholder="Message"
                        className="form-control"
                        rows={3}
                        value={formData.message}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    {/* reCAPTCHA widget */}
                    <div className="form-group">
                      <ReCAPTCHA
                        sitekey="6LcSWKYqAAAAAGYvChgsi6Cob3xzHlBnEz_3ZoPx" // Replace with your Google reCAPTCHA site key
                        onChange={handleCaptchaChange}
                      />
                    </div>

                    <div className="form-group">
                      <button
                        type="submit"
                        className="site-button text-uppercase font-weight-600"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Submitting..." : "Submit"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
