import { useEffect } from "react";
import { loadScript } from "../../../../globals/constants";
import FastImage from "../../../../globals/elements/fastimg";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import OwlCarousel from "react-owl-carousel";
import ContactForm from "../../contactForm";

// Table column definitions
const columns = [
  { id: "SPECIFICATION", label: "Specification", minWidth: 170 },
  { id: "Result_700", label: "Result (%)", minWidth: 100 },
  // { id: "Result_500", label: "Result (%)", minWidth: 100 },
];

// Function to create rows
function createData(SPECIFICATION, Result_700, Result_500) {
  return { SPECIFICATION, Result_700,   };
}

// Table row data
// const rows = [
//   createData("Calcium Oxide (CaO)", "85-90", "80-85"),
//   createData("Calcium Carbonate (CaCO3)", "3", "5"),
//   createData("Magnesium Oxide (MgO)", "1", "1"),
//   createData("Acid Insoluble (SiO2)", "3", "4"),
//   createData("Mesh (BSS)", "Lumps", "Lumps"),
//   createData("Alumina (Al₂O₃)", "0.1", "0.1"),
//   createData("Iron (Fe₂O₃)", "0.1", "0.1"),
//   createData("Chloride (Cl)", "Traces", "Traces"),
// ];
const rows = [
  createData("Calcium Oxide (CaO)MgCO₃", "90",   ),
  createData("Calcium Carbonate (CaCO3)", "3",  ),
  createData("Magnesium Oxide (MgO)", "1",  ),
  createData("Acid Insoluble (SiO2)", "3",   ),
  createData("Mesh (BSS)", "Lumps",  ),
  createData("Alumina (Al₂O₃)", "0.1",  ),
  createData("Iron (Fe₂O₃)", "0.1",  ),
  createData("Chloride (Cl)", "Traces",   ),
];

function QuickLimePowder() {
  // Load custom script on mount
  useEffect(() => {
    loadScript("js/custom.js");
  }, []);

  return (
    <>
      {/* Quicklime Overview Section */}
      <div className="section-full p-t80 p-b50">
        <div className="container bg-white">
          <div className="section-content">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <h2 className="normalheading" style={{
                  lineHeight:"52px"
                }}> Calcined/Quick Lime: Essential for Industrial Processes</h2>
                <p>
                  Quicklime, also known as calcium oxide (CaO), is a highly reactive material derived from the thermal
                  decomposition of limestone in a process called calcination. It plays a crucial role in various
                  industries, especially in steel manufacturing, construction, chemical production, and environmental
                  management.
                </p>
                <p>
                  Quicklime is widely used for purifying steel, neutralizing acidic soils in agriculture, treating
                  drinking water and wastewater, and producing mortar in construction. Its reactive properties allow it
                  to absorb moisture and carbon dioxide, making it indispensable for processes requiring high-quality
                  lime products.
                </p>
              </div>
              <div className="col-lg-6 col-md-12">
                <OwlCarousel className="service-detail-carousel" items={1} loop nav dots>
                  <div className="item">
                    <FastImage src="images/products/CalcinedQuick Lime-new.png" alt="Quicklime" />
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Specifications Table Section */}
      <div className="section-full p-t80 p-b50">
        <div className="container bg-white">
          <h3 style={{ textAlign: "center" }} className="normalheading">Calcined/Quick Lime Specification Report</h3>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id} style={{ minWidth: column.minWidth }}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, idx) => (
                    <TableRow key={idx}>
                      {columns.map((column) => (
                        <TableCell key={column.id}>{row[column.id]}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </div>

      {/* Contact Us Section */}
      <ContactForm/>
    </>
  );
}

export default QuickLimePowder;
