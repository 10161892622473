import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { loadScript, route } from "../../../../globals/constants";
import FastImage from "../../../../globals/elements/fastimg";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import OwlCarousel from "react-owl-carousel";
import TableRow from "@mui/material/TableRow";
import ContactForm from "../../contactForm";

const columns = [
  { id: "SPECIFICATION", label: "SPECIFICATION", minWidth: 170 },
  { id: "col1", label: "Result (%)", minWidth: 100 },
  { id: "col2", label: "Result (%)", minWidth: 100 },
  { id: "col3", label: "Result (%)", minWidth: 100 },
  { id: "col4", label: "Result (%)", minWidth: 100 },
  { id: "col5", label: "Result (%)", minWidth: 100 },
  { id: "col6", label: "Result (%)", minWidth: 100 },
  { id: "col7", label: "Result (%)", minWidth: 100 },
  
];

function createData(SPECIFICATION, ...cols) {
  const data = { SPECIFICATION };
  cols.forEach((col, index) => {
    data[`col${index + 1}`] = col;
  });
  return data;
}

const rows = [
  // createData("Available lime as CaCO3", 	95, 90, 85, 80, 75, 70, 65, ),
  createData("  CaCo₃", 	95, 90, 85, 80, 75, 70, 65, ),
  //createData("Acid Insoluble (max.)", 1,1.5, 2.5, 3.5, 4,4.5,6  ),
  createData("MgCo₃", 1,1.5, 2.5, 3.5, 4,4.5,6  ),
  createData("Silica",  "IN Traces", "IN Traces", "IN Traces", "IN Traces", "IN Traces", "IN Traces", "IN Traces",  ),
  createData("Fe₂O₃",  "2", "5", "5.25", "5.25", "5.5", "5", "8",  ),
  // createData("MgCO3 (Magnesia)(Max.)", 2, 5, 5.25, 5.25, 5.5, 5, 8,  ),
  //createData("SiO2(Max.)", 1, 1, 1.5, 1.5, 1.5, 1.5, 1.5,  ),
  createData("Others ", 1, 1, 1.5, 1.5, 1.5, 1.5, 1.5,  ),
  createData("Mesh(BSS)", 	400, 275, 225 , 200   , 175, 150  , 150 ,  ),
   
];

function HydratedLimePowder() {
  useEffect(() => {
    loadScript("js/custom.js");
  }, []);

  const [page, setPage] = useState(0);
  const rowsPerPage = 10;

  return (
    <>
      <div className="section-full p-t80 p-b50">
        <div className="container bg-white">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="mt-box">
                <h2 className="m-t0 normalheading" style={{
                  lineHeight:"52px"
                }}>Hydrated Lime: A Crucial Industrial Material</h2>
                <p>
                Hydrated lime, also known as calcium hydroxide (Ca(OH)₂), is produced by adding water to quicklime in a process called slaking. This fine, white powder is highly valued for its versatility and reactivity in various industrial applications.
                </p>
                <p>
                Hydrated lime is widely used in water treatment to soften water and adjust pH levels, in wastewater treatment for neutralizing acidic wastes, and in construction for improving mortar and plaster strength. It is also essential in environmental management for controlling emissions in industrial processes and in agriculture for balancing soil pH and enhancing soil quality. Hydrated lime's broad utility makes it a vital resource in multiple sectors.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <OwlCarousel className="owl-carousel service-detail-carousel" items={1} loop nav dots>
                <div className="item">
                  <FastImage src="images/products/Hydrated Lime-new.png" alt="Hydrated Lime Powder" />
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>

      <div className="section-full p-t80 p-b50">
        <div className="container bg-white">
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <h3 className="normalheading"
               style={
              {
                marginLeft:"0px",
                textAlign:"center"
              }
              
                }>   Hydrated Lime Powder Specification</h3>
            <TableContainer sx={{ maxHeight: 700 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id} style={{ minWidth: column.minWidth }}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((row) => (
                    <TableRow key={row.SPECIFICATION}>
                      {columns.map((column) => (
                        <TableCell key={column.id}>{row[column.id] || "-"}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </div>

      
      <ContactForm/>
    </>
  );
}

export default HydratedLimePowder;
