import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

export const Inquiry = () => {
  const [formData, setFormData] = useState({
    name : "",
    email: "",
    phone_number: "",
    address: "",
    subject: "",
    select_product: "",
    message: "",
  });
  const [captchaValue, setCaptchaValue] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!captchaValue) {
      alert("Please complete the CAPTCHA");
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await fetch("https://www.chemilex.com/inquiry.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Failed to submit the form. Please try again.");
      }

      const result = await response.json();
      alert("Form submitted successfully!");
      console.log(result);
      setFormData({
        name : "",
        email: "",
        phone_number: "",
        address: "",
        subject: "",
        select_product: "",
        message: "",
      });
      setCaptchaValue(null);
    } catch (error) {
      alert(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="section-full">
      <div className="container">
        <div className="section-head">
          <h2 className="AgencyFontheading">Inquiry Form </h2>
          <div className="mt-separator-outer m-b30">
            <div className="mt-separator site-bg-primary" />
          </div>
        </div>
        <div className="section-content m-b50">
          <div className="mt-box">
            <div className="p-a30 bg-gray radius-10 cons-contact-form-wrap">
              <form
                className="cons-contact-form contact-style-1"
                onSubmit={handleSubmit}
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        name="name"
                        type="text"
                        required
                        className="form-control"
                        placeholder="Name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        name="email"
                        type="text"
                        required
                        className="form-control"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        name="number"
                        type="number"
                        required
                        className="form-control"
                        placeholder="Phone Number"
                        value={formData.number}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        name="address"
                        type="text"
                        required
                        className="form-control"
                        placeholder="Address"
                        value={formData.address}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        name="subject"
                        type="text"
                        required
                        className="form-control"
                        placeholder="Subject"
                        value={formData.subject}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <select
                        name="select_product"
                        className="form-control"
                        value={formData.select_product}
                        onChange={handleChange}
                      >
                        <option value="01" selected disabled>Select Product</option>
                        <option value="0">Hydrated Lime Powder</option>
                        <option value="1">Quick Lime Powder</option>
                        <option value="2">Lime Stone</option>
                        <option value="3">Quick Lime Lumps</option>
                        <option value="4">Prime Hard Cooking Coal</option>
                        <option value="5">Indonesian Steam Coal</option>
                        <option value="6">South African Steam Coal</option>
                        <option value="7">PIC Coal</option>
                        <option value="8">Anthracite Coal</option>
                        <option value="9">Chrome Concentrate</option>
                        <option value="10">Gypsum</option>
                        <option value="11">Imported Managenese Ore(East Africa)</option>
                        <option value="12">Indonesian MET Coke</option>
                        <option value="13">China MET Coke</option>
                        <option value="14">Colombian MET Coke</option>
                        <option value="15">Colombian(ULP)</option>
                        <option value="16">Indian LAM Coke</option>
                        <option value="17">Rail Transport</option>
                        <option value="18">Road Transport</option>
                        {/* Add other options here */}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <textarea
                        name="message"
                        rows={5}
                        className="form-control"
                        required
                        placeholder="Message"
                        value={formData.message}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <ReCAPTCHA
                        sitekey="6LcSWKYqAAAAAGYvChgsi6Cob3xzHlBnEz_3ZoPx"
                        onChange={handleCaptchaChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <button
                      type="reset"
                      className="site-button m-r10"
                      disabled={isSubmitting}
                    >
                      Reset
                    </button>
                    <button
                      type="submit"
                      className="site-button-secondry"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
