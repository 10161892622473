import { useEffect, useState } from "react";
import { loadScript, route } from "../../../../globals/constants";
import FastImage from "../../../../globals/elements/fastimg";
import { NavLink } from "react-router-dom";

function SectionHeader1() {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    loadScript("js/sticky-header.js");
  });

  const handleToggleMenu = () => {
    setIsActive(!isActive);
  };

  return (
    <header
      className={
        "site-header header-style-3 mobile-sider-drawer-menu " +
        (isActive ? "active" : "")
      }
    >
      {/* Search Link */}
      <div className="main-bar header-middle bg-white">
        <div className="container">
          <div className="logo-header hide-skew-section">
            <NavLink to={route.INITIAL}>
              {/* <FastImage
                src="images/Original Logo.jpg"
                width={216}
                height={37}
                alt=""
              /> */}
              <FastImage
                src="images/industries_Chemilex_Final-01.png "
                width={216}
                height={37}
                alt=""
              />
            </NavLink>
          </div>
          <div className="header-info">
            <ul>
              <li>
                <div>
                  <div className="icon-sm">
                    <span className="icon-cell  site-text-primary">
                      <i className="iconmoon-smartphone" />
                    </span>
                  </div>
                  <div className="icon-content">
                    <strong className="headerFont">Call Us </strong>
                    <a href="tel:+919909107770">
                      <span>+91 99091 07770</span>
                    </a>

                    <br />
                    <a href="tel:+919879879925">
                      <span>+91 98798 79925</span>
                    </a>
                  </div>
                </div>
              </li>
              <li>
                <div>
                  <div className="icon-sm">
                    <span className="icon-cell  site-text-primary">
                      <i className="iconmoon-envelope" />
                    </span>
                  </div>
                  <div className="icon-content">
                    <strong className="headerFont">Email Us</strong>
                    <a href="mailto:info@chemilex.com">
                      <span>info@chemilex.com</span>
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="sticky-header main-bar-wraper">
        <div className="main-bar header-botton nav-bg-primary">
          <div className="container">
            {/* NAV Toggle Button */}
            <button
              id="mobile-side-drawer"
              data-target=".header-nav"
              data-toggle="collapse"
              type="button"
              className="navbar-toggler collapsed"
              onClick={handleToggleMenu}
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar icon-bar-first" />
              <span className="icon-bar icon-bar-two" />
              <span className="icon-bar icon-bar-three" />
            </button>
          
            <div className="logo-show-in-mobile">
              <NavLink to={route.INITIAL}>
                <FastImage
                  src="images/Original Logo.jpg"
                  width="100px !important"
                  height="20px !important"
                  alt=""
                />
              </NavLink>
            </div>
            {/* MAIN Vav */}
            <div className="header-nav navbar-collapse collapse ">
              <ul className=" nav navbar-nav">
                <li className="active">
                  <NavLink to={route.INITIAL} className="headerFont">Home </NavLink>
                
                </li>
                <li>
                  {/* <a href="#">Pages<i className="fa fa-chevron-down" /></a> */}
                  <NavLink to={route.pages.ABOUT} className="headerFont">About us</NavLink>
                 
                </li>
                <li>
                  <NavLink to={route.Products.PRODUCT} className="headerFont">
                    Products <i class="fa fa-chevron-down"></i>
                  </NavLink>
                  <ul className="sub-menu">
                    <li>
                      <NavLink to={route.Products.LimeProduct} className="headerFont">
                        {" "}
                        Lime Products  

                      </NavLink>
                      <ul className="sub-menu">
                        <li>
                          <NavLink to={route.Products.QuickLimeStone} className="headerFont">
                            Lime Stone
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={route.Products.HydratedLimePowder} className="headerFont">
                            Hydrated Lime
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={route.Products.QuickLimePowder} className="headerFont">
                            Calcined/Quick Lime
                          </NavLink>
                        </li>

                        <li>
                          <NavLink to={route.Products.QuickLimeLumps} className="headerFont">
                            Calcined/Quick Lime Powder
                          </NavLink>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <NavLink to={route.Products.ImportedIndianCoalCokes} className="headerFont">
                        Imported & Indian Coal & Cokes {" "}
                      </NavLink>
                      <ul className="sub-menu">
                        <li>
                          <NavLink to={route.Products.IndianImportedCoal} className="headerFont">
                            Coal - Imported / Indian Coal
                          </NavLink>
                          <ul className="sub-menu">
                            <li>
                              <NavLink to={route.Products.ImportedCoal} className="headerFont">
                                Imported Coal
                              </NavLink>
                              <ul className="sub-menu">
                                <li>
                                  <NavLink
                                    to={route.Products.PrimeHardCookingCoal}
                                    className="headerFont"
                                  >
                                    Prime Hard Coking Coal
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink
                                    to={route.Products.IndonesianSteamCoal}
                                    className="headerFont"
                                  >
                                    Indonesian Steam Coal
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink
                                    to={route.Products.SouthAfricanSteamCoal}
                                    className="headerFont"
                                  >
                                    South African Steam Coal
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink to={route.Products.PCICoal} className="headerFont">
                                    PCI Coal
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink to={route.Products.AnthraciteCoal} className="headerFont">
                                    Anthracite Coal
                                  </NavLink>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <NavLink to={route.Products.IndianCoalGrade} className="headerFont">
                                Indian Coal – Grade 1 to Grade 17
                              </NavLink>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <NavLink to={route.Products.IndianImportedCoke} className="headerFont">
                            Coke – Imported / Indian Coke
                          </NavLink>
                          <ul className="sub-menu">
                            <li>
                              <NavLink to={route.Products.IndonesianMetCoke} className="headerFont">
                                Indonesian MET Coke
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to={route.Products.ChinaMetCoke} className="headerFont">
                                China MET Coke
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to={route.Products.ColombianMetCoke} className="headerFont">
                                Colombian MET Coke
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to={route.Products.ColombianULP} className="headerFont">
                                Colombian (ULP)
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to={route.Products.IndianLAMCoke} className="headerFont">
                                Indian LAM Coke
                              </NavLink>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <NavLink to={route.Products.OtherMiningProducts} className="headerFont">
                        Other Mining Products{" "}
                      </NavLink>
                      <ul className="sub-menu">
                        <li>
                          <NavLink to={route.Products.ChromeConcentrate} className="headerFont">
                            Chrome Concentrate
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={route.Products.Gypsum} className="headerFont">Gypsum</NavLink>
                        </li>
                        <li>
                          <NavLink to={route.Products.ImportedMagnesesOre} className="headerFont">
                            {" "}
                            Imported Mangenese Ore (East Africa){" "}
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <NavLink to={route.Products.LogisticTransport} className="headerFont" >
                        {" "} 
                        Logistics & Transport
                      </NavLink>
                      <ul className="sub-menu">
                        <li>
                          <NavLink to={route.Products.RoadTransport} className="headerFont">
                            Road Transport
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={route.Products.RailTransport} className="headerFont">
                            Rail Transport
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <NavLink to={route.pages.contact.Inquiry} className="headerFont">Inquiry</NavLink>
                  {/* <ul className="sub-menu">
                                    <li></li>
                                    <li><NavLink to={route.blog.LIST_SIDEBAR}>Blog-List-Sidebar</NavLink></li>
                                    <li><NavLink to={route.blog.GRID}>Blog-Grid</NavLink></li>
                                    <li><NavLink to={route.blog.GRID_SIDEBAR}>Blog-Grid-Sidebar</NavLink></li>
                                    <li><NavLink to={route.blog.POST_SINGLE}>Blog-Post-single</NavLink></li>
                                </ul> */}
                </li>
                {/* <li>
                            <NavLink  path={route.pages.contact.CONTACT1}>Contact US</NavLink>
                                <ul className="sub-menu">
                                    <li><NavLink to={route.shop.PRODUCTS}>Product</NavLink></li>
                                    <li><NavLink to={route.shop.DETAIL}>Product Detail</NavLink></li>
                                    <li><NavLink to={route.shop.CART}>Shopping cart</NavLink></li>
                                    <li><NavLink to={route.shop.WISHLIST}>Wishlist</NavLink></li>
                                    <li><NavLink to={route.shop.CHECKOUT}>Checkout</NavLink></li>
                                </ul>
                            </li> */}
                <li>
                  <NavLink to={route.pages.contact.CONTACT1} className="headerFont">
                    Contact US
                  </NavLink>
                </li>
                <li>
                  <NavLink to={route.Career.CAREER} className="headerFont">Career</NavLink>
                </li>
                <li>
                  <NavLink to={route.Career.Clients} className="headerFont">Our Clients</NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
export default SectionHeader1;
