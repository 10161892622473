import { useEffect } from "react";
// import FastImage from "../../../globals/elements/fastimg";
// import { loadScript, route } from "../../../globals/constants";
import { NavLink } from "react-router-dom";
import { loadScript, route } from "../../../../globals/constants";
import FastImage from "../../../../globals/elements/fastimg";
import OwlCarousel from 'react-owl-carousel';
import ContactForm from "../../contactForm";
function ColombianMetCoke() {
  useEffect(() => {
    loadScript("js/custom.js");
  });

  return (
    <>
      {/* Service Detail */}
      <div className="section-full p-t80 p-b50 ">
        <div className="container bg-white ">
          <div className="section-content ">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="mt-box">
                  <h2 className="m-t0 normalheading" style={{
                  lineHeight:"52px"
                }}>Colombian Met Coke</h2>

                  <p>
                    Colombian Met Coke, or Colombian metallurgical coke, is a
                    high-quality coke produced from premium coking coal found in
                    Colombia. It is known for its strong mechanical properties,
                    high carbon content, and low impurity levels, making it
                    ideal for use in steelmaking processes.
                  </p>
                  <p>
                    The Colombian Met Coke is manufactured to meet stringent
                    quality standards, ensuring reliable performance in blast
                    furnaces. Its superior combustion characteristics and
                    efficiency contribute to enhanced productivity in iron
                    production, making it a preferred choice for steel producers
                    seeking high-quality, effective coke solutions.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
              <OwlCarousel
                      className="owl-carousel service-detail-carousel owl-btn-vertical-center owl-dots-bottom-center m-b20"
                      items={1}
                      loop
                      nav
                      dots
                      mouseDrag={false} // Disable mouse dragging
                      touchDrag={false} // Disable touch dragging
                    > 
                  <div className="item">
                    <div className="aon-thum-bx">
                      <FastImage src="images/products/Colombian MET COKE.jpg" alt="" />
                    </div>
                  </div>
                  </OwlCarousel>
              
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-full p-t80 p-b50 bg-gray">
        <div className="container">
          {/* TITLE START*/}
          <div className="section-head text-center">
            <h2 data-title="Supplies" className="normalheading">  Services</h2>
            <div className="mt-separator-outer">
              <div className="mt-separator site-bg-primary" />
            </div>
          </div>
          {/* TITLE END*/}
          <div className="row d-flex justify-content-center">
            <div className="col-lg-4 col-md-6">
             <div className="mt-box bg-white m-b30 mt-service-2">
                <div className="mt-media">
                  <NavLink to={route.Products.IndonesianMetCoke}>
                  <OwlCarousel
                      className="owl-carousel service-detail-carousel owl-btn-vertical-center owl-dots-bottom-center m-b20"
                      items={1}
                      loop
                      nav
                      dots
                      mouseDrag={false} // Disable mouse dragging
                      touchDrag={false} // Disable touch dragging
                    > 
                      <div className="item">
                        <div className="aon-thum-bx">
                          <FastImage src="images/products/IndonesianMETCoke.jpg" alt="" />
                        </div>
                      </div>
                       
                      </OwlCarousel>

                  </NavLink>
                </div>
                <div className="mt-icon-box-wraper p-a30 left bg-white">
                  <div className="icon-content">
                    <h4 className="mt-tilte m-t0">Indonesian MET Coke</h4>

                    <NavLink
                      to={route.Products.IndonesianMetCoke}
                     className="site-button-link site-text-primary font-weight-600"
                    >
                      Read More
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
             <div className="mt-box bg-white m-b30 mt-service-2">
                <div className="mt-media">
                  <NavLink to={route.Products.ChinaMetCoke}>
                  <OwlCarousel
                      className="owl-carousel service-detail-carousel owl-btn-vertical-center owl-dots-bottom-center m-b20"
                      items={1}
                      loop
                      nav
                      dots
                      mouseDrag={false} // Disable mouse dragging
                      touchDrag={false} // Disable touch dragging
                    > 
                      <div className="item">
                        <div className="aon-thum-bx">
                          <FastImage src="images/products/ChinaMETCoke.jpg" alt="" />
                        </div>
                      </div>
                      </OwlCarousel>
                     
                  </NavLink>
                </div>
                <div className="mt-icon-box-wraper p-a30 left bg-white">
                  <div className="icon-content">
                    <h4 className="mt-tilte m-t0">China MET Coke</h4>

                    <NavLink
                      to={route.Products.ChinaMetCoke}
                     className="site-button-link site-text-primary font-weight-600"
                    >
                      Read More
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
             <div className="mt-box bg-white m-b30 mt-service-2">
                <div className="mt-media">
                  <NavLink to={route.Products.ColombianULP}>
                  <OwlCarousel
                      className="owl-carousel service-detail-carousel owl-btn-vertical-center owl-dots-bottom-center m-b20"
                      items={1}
                      loop
                      nav
                      dots
                      mouseDrag={false} // Disable mouse dragging
                      touchDrag={false} // Disable touch dragging
                    > 
                      <div className="item">
                        <div className="aon-thum-bx">
                          <FastImage src="images/products/Colombian (ULP).png" alt="" />
                        </div>
                      </div>
                     
                      </OwlCarousel>

                  </NavLink>
                </div>
                <div className="mt-icon-box-wraper p-a30 left bg-white">
                  <div className="icon-content">
                    <h4 className="mt-tilte m-t0">Colombian (ULP)</h4>

                    <NavLink
                      to={route.Products.ColombianULP}
                     className="site-button-link site-text-primary font-weight-600"
                    >
                      Read More
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
             <div className="mt-box bg-white m-b30 mt-service-2">
                <div className="mt-media">
                  <NavLink to={route.Products.IndianLAMCoke}>
                  <OwlCarousel
                      className="owl-carousel service-detail-carousel owl-btn-vertical-center owl-dots-bottom-center m-b20"
                      items={1}
                      loop
                      nav
                      dots
                      mouseDrag={false} // Disable mouse dragging
                      touchDrag={false} // Disable touch dragging
                    > 
                      <div className="item">
                        <div className="aon-thum-bx">
                          <FastImage src="images/products/IndianLAMCoke.jpg" alt="" />
                        </div>
                      </div>
                      </OwlCarousel>
                   
                  </NavLink>
                </div>
                <div className="mt-icon-box-wraper p-a30 left bg-white">
                  <div className="icon-content">
                    <h4 className="mt-tilte m-t0"> Indian LAM Coke</h4>

                    <NavLink
                      to={route.Products.IndianLAMCoke}
                     className="site-button-link site-text-primary font-weight-600"
                    >
                      Read More
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactForm/>
    </>
  );
}
export default ColombianMetCoke;
