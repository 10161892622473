import { useEffect } from "react";
// import FastImage from "../../../globals/elements/fastimg";
// import { loadScript, route } from "../../../globals/constants";
import { NavLink } from "react-router-dom";
import { loadScript, route } from "../../../../globals/constants";
import FastImage from "../../../../globals/elements/fastimg";
import OwlCarousel from 'react-owl-carousel';
import ContactForm from "../../contactForm";
function ImportedMagnesesOre() {

    useEffect(()=>{
        loadScript("js/custom.js")
    })

    return (
        <>
            {/* Service Detail */}
            <div className="section-full  p-t80 p-b50  ">
                <div className="container  bg-white ">
                    <div className="section-content ">
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <div className="mt-box">
                                    <h2 className="m-t0 normalheading" style={{
                  lineHeight:"52px"
                }} >Imported Magneses Ore</h2>
                                    
                                    <p>
                                    Imported Manganese Ore is a high-quality mineral essential for various industrial applications, particularly in steel production. Manganese is a crucial alloying element that enhances the strength, toughness, and hardness of steel, making it indispensable for manufacturing construction materials, automotive components, and machinery.
                                    </p>
                                    <p>An Imported Manganese Ore is sourced from the best global suppliers, ensuring high purity and consistent quality. Its excellent metallurgical properties make it a preferred choice for steelmakers looking to produce superior-grade steel. In addition to steel production, our manganese ore is also used in the production of batteries, chemicals, and aluminum alloys, demonstrating its versatility and value across different sectors.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                            <OwlCarousel
                      className="owl-carousel service-detail-carousel owl-btn-vertical-center owl-dots-bottom-center m-b20"
                      items={1}
                      loop
                      nav
                      dots
                      mouseDrag={false} // Disable mouse dragging
                      touchDrag={false} // Disable touch dragging
                    > 
                                    <div className="item">
                                        <div className="aon-thum-bx">
                                            <FastImage src="images/products/Our Products .jpg" alt="" />
                                        </div>
                                    </div>
                                    </OwlCarousel>     
                             
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-full p-t80 p-b50 bg-gray">
        <div className="container">
          {/* TITLE START*/}
          <div className="section-head text-center">
            <h2 data-title="Supplies" className="normalheading">  Services</h2>
            <div className="mt-separator-outer">
              <div className="mt-separator site-bg-primary" />
            </div>
          </div>
          {/* TITLE END*/}
          <div className="row d-flex justify-content-center">
            <div className="col-lg-4 col-md-6">
             <div className="mt-box bg-white m-b30 mt-service-2">
                <div className="mt-media">
                  <NavLink to={route.Products.Gypsum}>
                  <OwlCarousel
                      className="owl-carousel service-detail-carousel owl-btn-vertical-center owl-dots-bottom-center m-b20"
                      items={1}
                      loop
                      nav
                      dots
                      mouseDrag={false} // Disable mouse dragging
                      touchDrag={false} // Disable touch dragging
                    > 
                      <div className="item">
                        <div className="aon-thum-bx">
                          <FastImage src="images/products/51.jpg" alt="" />
                        </div>
                      </div>
                      </OwlCarousel>

                    
                  </NavLink>
                </div>
                <div className="mt-icon-box-wraper  p-a30 left bg-white">
                  <div className="icon-content">
                    <h4 className="mt-tilte m-t0">Gypsum</h4>

                    <NavLink
                      to={route.Products.Gypsum}
                     className="site-button-link site-text-primary font-weight-600"
                    >
                      Read More
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
             <div className="mt-box bg-white m-b30 mt-service-2">
                <div className="mt-media">
                  <NavLink to={route.Products.ChromeConcentrate}>
                  <OwlCarousel
                      className="owl-carousel service-detail-carousel owl-btn-vertical-center owl-dots-bottom-center m-b20"
                      items={1}
                      loop
                      nav
                      dots
                      mouseDrag={false} // Disable mouse dragging
                      touchDrag={false} // Disable touch dragging
                    > 
                      <div className="item">
                        <div className="aon-thum-bx">
                          <FastImage src="images/products/52.jpg" alt="" />
                        </div>
                      </div>
                      
                      </OwlCarousel>

                  </NavLink>
                </div>
                <div className="mt-icon-box-wraper  p-a30 left bg-white">
                  <div className="icon-content">
                    <h4 className="mt-tilte m-t0">Chrome Concentrate</h4>

                    <NavLink
                      to={route.Products.ChromeConcentrate}
                     className="site-button-link site-text-primary font-weight-600"
                    >
                      Read More
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            
             
          </div>
        </div>
      </div>
      <ContactForm/>
        </>
    )
}
export default ImportedMagnesesOre;