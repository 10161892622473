/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

// Use the environment variable for the base path
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter  >
    <App />
  </BrowserRouter>
);

reportWebVitals();
