import { useEffect } from "react";
// import FastImage from "../../../globals/elements/fastimg";
// import { loadScript, route } from "../../../globals/constants";
import { NavLink } from "react-router-dom";
import { loadScript, route } from "../../../../globals/constants";
import FastImage from "../../../../globals/elements/fastimg";
import OwlCarousel from "react-owl-carousel";
import ContactForm from "../../contactForm";
function RoadTransport() {
  useEffect(() => {
    loadScript("js/custom.js");
  });

  return (
    <>
      {/* Service Detail */}
      <div className="section-full  p-t80 p-b50  ">
        <div className="container  bg-white ">
          <div className="section-content ">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="mt-box">
                  <h2 className="m-t0 normalheading" style={{
                  lineHeight:"52px"
                }}>Road Transport</h2>

                  <p>
                    Road Transport is a vital part of the logistics chain,
                    enabling the efficient movement of goods across various
                    locations. It offers flexibility and accessibility, making
                    it ideal for transporting goods over short to medium
                    distances. From delivering raw materials to factories to
                    distributing finished products to markets, road transport
                    plays a crucial role in ensuring supply chain continuity and
                    timely delivery.
                  </p>
                  <p>
                    At Chemilex Industries, we provide reliable and efficient
                    road transport services to meet the diverse needs of our
                    clients. Our fleet of well-maintained vehicles and
                    professional drivers ensures safe and timely delivery of
                    products, regardless of the destination. With a focus on
                    reliability, cost-effectiveness, and customer satisfaction,
                    our road transport solutions are designed to support the
                    smooth operation of your business and ensure your goods
                    reach their destination safely.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <OwlCarousel
                  className="owl-carousel service-detail-carousel owl-btn-vertical-center owl-dots-bottom-center m-b20"
                  items={1}
                  loop
                  nav
                  dots
                  mouseDrag={false} // Disable mouse dragging
                  touchDrag={false} // Disable touch dragging
                >
                  <div className="item">
                    <div className="aon-thum-bx">
                      <FastImage
                        src="images/products/RoadTransport.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-full p-t80 p-b50 bg-gray">
        <div className="container">
          {/* TITLE START*/}
          <div className="section-head text-center">
            <h2 data-title="Supplies" className="normalheading">Services </h2>
            <div className="mt-separator-outer">
              <div className="mt-separator site-bg-primary" />
            </div>
          </div>
          {/* TITLE END*/}
          <div className="row d-flex justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="mt-box bg-white m-b30 mt-service-2">
                <div className="mt-media">
                  <NavLink to={route.Products.RailTransport}>
                    <FastImage
                      src="images/products/Rail Transport.jpg"
                      alt=""
                    />
                  </NavLink>
                </div>
                <div className="mt-icon-box-wraper  p-a30 left bg-white">
                  <div className="icon-content">
                    <h4 className="mt-tilte m-t0">Rail Transport</h4>

                    <NavLink
                      to={route.Products.RailTransport}
                      className="site-button"
                    >
                      Read More
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactForm/>
    </>
  );
}
export default RoadTransport;
