import { useEffect } from "react";
// import FastImage from "../../../globals/elements/fastimg";
// import { loadScript, route } from "../../../globals/constants";
import { NavLink } from "react-router-dom";
import { loadScript, route } from "../../../../globals/constants";
import FastImage from "../../../../globals/elements/fastimg";
import OwlCarousel from 'react-owl-carousel';
import ContactForm from "../../contactForm";
function ChromeConcentrate() {
  useEffect(() => {
    loadScript("js/custom.js");
  });

  return (
    <>
      {/* Service Detail */}
      <div className="section-full  p-t80 p-b50  ">
        <div className="container  bg-white ">
          <div className="section-content ">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="mt-box">
                  <h2 className="m-t0 normalheading" style={{
                  lineHeight:"52px"
                }}>Chrome Concentrate</h2>

                  <p>
                    Chrome Concentrate is a high-grade material derived from
                    chromite ore, known for its high chromium content and low
                    impurity levels. It is widely used in the production of
                    ferrochrome, a key component in stainless steel
                    manufacturing. The high-quality concentrate offers excellent
                    metallurgical properties, making it a vital resource for
                    industries that require strong, corrosion-resistant steel.
                  </p>
                  <p>
                  The Chrome Concentrate is sourced
                    from the best mining regions and processed to meet stringent
                    quality standards. Its high chromium-to-iron ratio ensures
                    optimal performance in smelting processes, making it an
                    essential material for stainless steel producers. The
                    reliability and purity of our Chrome Concentrate help
                    manufacturers achieve superior product quality, making it a
                    trusted choice for industrial applications.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
              <OwlCarousel
                      className="owl-carousel service-detail-carousel owl-btn-vertical-center owl-dots-bottom-center m-b20"
                      items={1}
                      loop
                      nav
                      dots
                      mouseDrag={false} // Disable mouse dragging
                      touchDrag={false} // Disable touch dragging
                    > 
                  <div className="item">
                    <div className="aon-thum-bx">
                      <FastImage src="images/products/52.jpg" alt="" />
                    </div>
                  </div>
                  
                  </OwlCarousel>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-full p-t80 p-b50 bg-gray">
        <div className="container">
          {/* TITLE START*/}
          <div className="section-head text-center">
            <h2 data-title="Supplies" className="normalheading">  Services</h2>
            <div className="mt-separator-outer">
              <div className="mt-separator site-bg-primary" />
            </div>
          </div>
          {/* TITLE END*/}
          <div className="row d-flex justify-content-center">
            <div className="col-lg-4 col-md-6">
             <div className="mt-box bg-white m-b30 mt-service-2">
                <div className="mt-media">
                  <NavLink to={route.Products.Gypsum}>
                  <OwlCarousel
                      className="owl-carousel service-detail-carousel owl-btn-vertical-center owl-dots-bottom-center m-b20"
                      items={1}
                      loop
                      nav
                      dots
                      mouseDrag={false} // Disable mouse dragging
                      touchDrag={false} // Disable touch dragging
                    > 
                      <div className="item">
                        <div className="aon-thum-bx">
                          <FastImage src="images/products/51.jpg" alt="" />
                        </div>
                      </div>
                      </OwlCarousel>

                     
                  </NavLink>
                </div>
                <div className="mt-icon-box-wraper  p-a30 left bg-white">
                  <div className="icon-content">
                    <h4 className="mt-tilte m-t0">Gypsum</h4>

                    <NavLink to={route.Products.Gypsum} className="site-button-link site-text-primary font-weight-600">
                      Read More
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
             <div className="mt-box bg-white m-b30 mt-service-2">
                <div className="mt-media">
                  <NavLink to={route.Products.ImportedMagnesesOre}>
                  <OwlCarousel
                      className="owl-carousel service-detail-carousel owl-btn-vertical-center owl-dots-bottom-center m-b20"
                      items={1}
                      loop
                      nav
                      dots
                      mouseDrag={false} // Disable mouse dragging
                      touchDrag={false} // Disable touch dragging
                    > 
                      <div className="item">
                        <div className="aon-thum-bx">
                          <FastImage src="images/products/Our Products .jpg" alt="" />
                        </div>
                      </div>
                      </OwlCarousel>
                     
                  </NavLink>
                </div>
                <div className="mt-icon-box-wraper  p-a30 left bg-white">
                  <div className="icon-content">
                    <h4 className="mt-tilte m-t0">
                      Imported Magneses Ore(East Africa)
                    </h4>

                    <NavLink
                      to={route.Products.ImportedMagnesesOre}
                     className="site-button-link site-text-primary font-weight-600"
                    >
                      Read More
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactForm/>
    </>
  );
}
export default ChromeConcentrate;
