import FastImage from "../../../../globals/elements/fastimg";
import { publicUrlFor } from "../../../../globals/constants";
import OwlCarousel from 'react-owl-carousel';

function SectionHome1Testimonials() {
  const testimonials = [
    { src: "images/testimonials/Atul_logo.png",   },
    { src: "images/testimonials/bharat-rasayan.png",   },
    { src: "images/testimonials/Biltech.jpeg",   },
    { src: "images/testimonials/Birla_Copper.jpeg",   },
    { src: "images/testimonials/BodalLogo.png",   },
    { src: "images/testimonials/Dabour.jpeg",   },
    { src: "images/testimonials/Delite_AAC.png",  },
    { src: "images/testimonials/Dipak_Phenolics.jpeg",   },
    { src: "images/testimonials/Jamipol-Logo.png",  },
    { src: "images/testimonials/kilburnlogo.jpg",  },
    { src: "images/testimonials/Meghmani.jpg",   },
    { src: "images/testimonials/Narmada_Gelatin.png",   },
    { src: "images/testimonials/Orbix.png", },
    { src: "images/testimonials/RBIL.png",    },
    { src: "images/testimonials/Shri_Puskar.png",  },
    { src: "images/testimonials/shubhalakshmi-logo.png",  },
    { src: "images/testimonials/Sun_Flag.jpeg",  },
    { src: "images/testimonials/Visa_Steel.jpeg",   },
    { src: "images/testimonials/kilburnlogo.jpg",   },
  ];

  const options = {
    items: 4, // Number of items to show at once
     loop: true, // Enable infinite looping
    margin: 10, // Margin between items
    autoplay: true, // Enable autoplay
    autoplayTimeout: 3000, // Time in ms for each slide
    autoplayHoverPause: true, // Pause autoplay on hover
    slideTransition: "linear", // Custom slide transition
    dots: false, // Disable dots
     // Enable navigation arrows
       // Custom navigation text
    responsive: {
      0: { items: 1 },
      600: { items: 2 },
      1000: { items: 3 },
    },
  };

 

  const testimonialTexts = [
    {
      
      text: "What sets Chemilex apart is their commitment to both excellence and sustainability. Their high-quality products and ethical practices reinforce our decision to continue the partnership.",
    },
    {
      
      text: "Our experience with Chemilex has been nothing but outstanding. The materials always meet our high standards, and the service team is consistently reliable, making every interaction smooth.",
    },
    {
       
      text: "The superior quality of Chemilex’s materials has had a noticeable impact on our productivity. Reliable deliveries and top-notch customer support are crucial to our success.",
    },
    {
       
      text: "More than just a supplier, Chemilex is a partner we can rely on for exceptional products and services. Their commitment to detail and sustainability aligns perfectly with our goals.",
    },
    {
       
      text: "Chemilex’s dedication to delivering consistent product quality and a customer-first approach truly sets them apart. Their dependability keeps us coming back time and time again.",
    },
    {
       
      text: "Chemilex consistently delivers both high-quality products and superior service, making them a trusted and reliable partner in our industry. We wouldn’t hesitate to recommend them.",
    },
    {
       
      text: "From our first interaction to the final delivery, the Chemilex team has been professional, dependable, and dedicated to meeting our needs. Their high-quality products are a vital part of our operations.",
    },
    {
       
      text: "The Chemilex team always goes above and beyond to ensure we receive exactly what we need, when we need it. Their product quality and support services make them one of the best suppliers we’ve worked with.",
    },
    {
       
      text: "Chemilex’s unwavering focus on quality, innovation, and sustainability makes them our trusted supplier. We look forward to continuing our partnership and growing together in the future.",
    },
  ];

  return (
    <>
      <div
        className="section-full p-t80 p-b80 bg-center bg-full-height bg-no-repeat bg-gray"
        style={{
          backgroundImage: `url(${publicUrlFor("images/background/bg-map.png")})`,
        }}
      >
        <div className="container">
          {/* TITLE START */}
          <div className="section-head text-center">
            <h2 data-title=" " className="AgencyFontheading">Our Client Say</h2>
            <div className="mt-separator-outer">
              <div className="mt-separator site-bg-primary" />
            </div>
          </div>
          {/* TITLE END */}
          <div className="section-content">
          <OwlCarousel className="owl-theme home-carousel-1" {...options}>
      {testimonials.map((testimonial, index) => (
        <div className="item" key={index}>
          <div className="testimonial-2 clearfix">
            <div className="testimonial-detail">
              <div className="testimonial-pic shadow" style={{
                display:"flex",
                justifyContent:"center",
                alignItems:"center"
              }}>
              <FastImage src={ testimonial.src} alt="" />
              </div>
            </div>
          </div>
        </div>
      ))}
    </OwlCarousel>
          </div>
        </div>
      </div>

      <div
        className="section-full p-t80 p-b80 bg-center bg-full-height bg-no-repeat bg-gray"
        style={{
          backgroundImage: `url(${publicUrlFor("images/background/bg-map.png")})`,
        }}
      >
        <div className="container">
          {/* TITLE START */}
          <div className="section-head text-center">
            <h2 data-title=" ">Our Client Say</h2>
            <div className="mt-separator-outer">
              <div className="mt-separator site-bg-primary" />
            </div>
          </div>
          {/* TITLE END */}
          <div className="section-content">
            <div className="owl-carousel home-carousel-1">
              {testimonialTexts.map((testimonial, index) => (
                <div key={index} className="item">
                  <div className="testimonial-2 clearfix bg-white">
                    <div className="testimonial-text">
                      <span className="testimonial-position">{testimonial.position}</span>
                      <span className="fa fa-quote-left" />
                      <p>{testimonial.text}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionHome1Testimonials;
