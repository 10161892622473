import { useEffect, useState } from "react";
// import FastImage from "../../../globals/elements/fastimg";
// import { loadScript, route } from "../../../globals/constants";
import { NavLink } from "react-router-dom";
import { loadScript, route } from "../../../../globals/constants";
import FastImage from "../../../../globals/elements/fastimg";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import OwlCarousel from 'react-owl-carousel';
import TableRow from "@mui/material/TableRow";
import { Paper } from "@mui/material";
import ContactForm from "../../contactForm";

const columns = [
  { id: "SPECIFICATION", label: "SPECIFICATION", minWidth: 200 },
  { id: "RESULT", label: "RESULT", minWidth: 200 },
   
];

function createData(SPECIFICATION, RESULT,  ) {
  return { SPECIFICATION, RESULT,   };
}

const rows = [
  createData("CaCO₃",   "92-94"),
  createData("MgCO₃",    "2.50(max.)"),
  createData("Silica",   "1.50(max.)"),
  createData("Fe₂O₃",   "0.2(max.)"),
  createData("Others  ",   "0.6"),
  createData("Mesh(BSS)",   "0-6mm/6-25mm/10-30mm/25-50mm/40-80mm"),
   
  createData("PACKING", "	Loose",  ),
];
function QuickLimeStone() {
  useEffect(() => {
    loadScript("js/custom.js");
    
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  return (
    <>
      {/* Service Detail */}
      <div className="section-full  p-t80 p-b50  ">
        <div className="container  bg-white ">
          <div className="section-content ">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="mt-box">
                  <h2 className="m-t0 normalheading" style={{
                  lineHeight:"52px"
                }}> Lime Stone: A Key Resource for Multiple Industries    </h2>

                  <p>
                  Limestone is a naturally occurring sedimentary rock primarily composed of calcium carbonate (CaCO₃). It is formed from the accumulation of marine organism shells, corals, and other organic materials over millions of years. Limestone serves as a critical raw material in lime manufacturing, where it is heated to produce quicklime (calcium oxide) and hydrated lime (calcium hydroxide). These compounds are essential in sectors such as construction, steel production, agriculture, water treatment, and more.
                  </p>
                  <p>
                  In addition to lime production, limestone is also a valuable component in cement manufacturing, Glass Industry, Poultry Feed / Cattle Feed, Ceramic Industry, Rubber Industry, Oil Exploration, and as a base material for many infrastructure projects. Its versatility and abundance make it a cornerstone of modern industrial processes.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
              <OwlCarousel
                      className="owl-carousel service-detail-carousel owl-btn-vertical-center owl-dots-bottom-center m-b20"
                      items={1}
                      loop
                      nav
                      dots
                      mouseDrag={false} // Disable mouse dragging
                      touchDrag={false} // Disable touch dragging
                    >

               
                  <div className="item">
                    <div className="aon-thum-bx">
                      <FastImage
                        src="images/products/Lime Stone-new.png"
                        alt=""
                      />
                    </div>
                  </div>
                 
               

                </OwlCarousel>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-full p-t80 p-b50">
      <div className="container bg-white">
        <div className="section-content">
          <div className="row">

            <div className="col-12">
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <h3
              className="normalheading"
               style={
              {
                marginLeft:"0px",
                textAlign:"center"
              }
              //  margin-left: 0px;
              //   text-align: center;
                }>   Lime Stone Specification</h3>
                <TableContainer sx={{ maxHeight: 600 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align="center" // Adjust alignment if needed
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => (
                          <TableRow hover role="checkbox" tabIndex={-1} key={row.SPECIFICATION}>
                            {columns.map((column) => (
                              <TableCell key={column.id} align="center"> {/* Adjust alignment if needed */}
                                {row[column.id]}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </div>


    <ContactForm/>
    </>
  );
}
export default QuickLimeStone;
