import { useEffect } from "react";
import { loadScript, route } from "../../../../globals/constants";
import FastImage from "../../../../globals/elements/fastimg";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import OwlCarousel from "react-owl-carousel";
import TableRow from "@mui/material/TableRow";
import { Paper } from "@mui/material";
import { NavLink } from "react-router-dom";
import ContactForm from "../../contactForm";

// Column definitions for both tables
const columns = [
  { id: "SPECIFICATION", label: "SPECIFICATION", minWidth: 200 },
  { id: "Result", label: "Result (%)", minWidth: 200 },
];

const columns2 = [
  { id: "InMinutes", label: "In Minutes   ", minWidth: 200 },
  { id: "TemperatureinDegree", label: "Temperature in Degree", minWidth: 200 },
];

// Data rows for the specification table
const rows = [
  { SPECIFICATION: "CaO", Result: "85-90" },
  { SPECIFICATION: "MgO", Result: "5" },
 
   
  { SPECIFICATION: "SiO₂", Result: "1.48" },
  { SPECIFICATION: "CO₂ (Oven Basis)", Result: "3.3" },
  { SPECIFICATION: "Lime as CaO", Result: "86.2" },
  { SPECIFICATION: "Mesh (BSS)", Result: " (0-3) mm, (0-5) mm" },
  { SPECIFICATION: "Packing", Result: "HDPE/Jumbo Bag" },
];

// Data rows for the temperature report table
const rows2 = [
  { InMinutes: "0", TemperatureinDegree: "20.04°C" },
  { InMinutes: "5", TemperatureinDegree: "33.40°C" },
  { InMinutes: "10", TemperatureinDegree: "41.50°C" },
  { InMinutes: "15", TemperatureinDegree: "49.50°C" },
  { InMinutes: "20", TemperatureinDegree: "54.50°C" },
  { InMinutes: "25", TemperatureinDegree: "56.06°C" },
  { InMinutes: "30", TemperatureinDegree: "58.11°C" },
];

function QuickLimeLumps() {
  useEffect(() => {
    loadScript("js/custom.js");
  }, []);

  return (
    <>
      {/* Quicklime Overview Section */}
      <div className="section-full p-t80 p-b50">
        <div className="container bg-white">
          <div className="section-content">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <h2 className="normalheading" style={{
                  lineHeight:"52px"
                }}>Quicklime Powder: A Versatile Industrial Solution</h2>
                <p>
                  Quicklime powder, also known as calcium oxide (CaO) in its finely ground form, is a highly reactive
                  and versatile material used across a wide range of industries. Produced by heating limestone in a
                  process called calcination, quicklime powder is widely used for its ability to absorb moisture and
                  neutralize acidity.
                </p>
                <p>
                  It is a critical component in steel production, where it helps remove impurities, and in construction,
                  where it is used for soil stabilization and mortar production. Additionally, quicklime powder plays an
                  important role in water treatment, flue gas desulfurization, and agricultural soil improvement.
                </p>
              </div>
              <div className="col-lg-6 col-md-12">
                <OwlCarousel
                  className="owl-carousel service-detail-carousel owl-btn-vertical-center owl-dots-bottom-center m-b20"
                  items={1}
                  loop
                  nav
                  dots
                  mouseDrag
                  touchDrag
                >
                  <div className="item">
                    <FastImage src="images/products/CalcinedQuick Lime Powder-new.png" alt="Quicklime Powder" />
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Specifications Table Section */}
      <div className="section-full p-t80 p-b50">
        <div className="container bg-white">
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <h3 style={{ textAlign: "center" }} className="normalheading">Calcined/Quick Lime Specification Report</h3>
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id} align="center" style={{ minWidth: column.minWidth }}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow hover key={index}>
                      {columns.map((column) => (
                        <TableCell key={column.id} align="center">
                          {row[column.id]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </div>

      {/* Temperature Report Table Section */}
      <div className="section-full p-t80 p-b50">
        <div className="container bg-white">
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <h3 style={{ textAlign: "center" }} className="normalheading">Calcined/Quick Lime Powder Temperature Report</h3>
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {columns2.map((column) => (
                      <TableCell key={column.id} align="center" style={{ minWidth: column.minWidth }}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows2.map((row, index) => (
                    <TableRow hover key={index}>
                      {columns2.map((column) => (
                        <TableCell key={column.id} align="center">
                          {row[column.id]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </div>

       
      <ContactForm/>
    </>
  );
}

export default QuickLimeLumps;
